import { useMemo } from "react";
import * as config from "../../../config/config";
import {
  AdviceDetailsContainer,
  AdviceExcerpt,
  AdviceImageBanner,
  AdviceTitle,
  LinkWrapper,
  Wrapper,
} from "./styles";

export default function AdviceCard(props) {
  const { blog } = props;

  const coverImage = useMemo(() => {
    if (!blog?.coverImage?.data?.attributes?.url) {
      return (
        config.assetDomain +
        "/images/backgrounds/hiredly-jobs-malaysia-wobb-background-v1.png"
      );
    }

    return blog?.coverImage?.data?.attributes?.url;
  }, [blog?.coverImage?.data?.attributes?.url]);

  return (
    <LinkWrapper href={`/advice/${blog?.slug}`} target="_blank">
      <Wrapper>
        <AdviceImageBanner
          src={coverImage}
          alt={blog?.title}
          loading={"lazy"}
        />

        <AdviceDetailsContainer>
          <AdviceTitle>{blog.title}</AdviceTitle>
          <AdviceExcerpt dangerouslySetInnerHTML={{ __html: blog.excerpt }} />
        </AdviceDetailsContainer>
      </Wrapper>
    </LinkWrapper>
  );
}
