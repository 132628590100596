import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";

// Grid Components //
export const GridTitle = styling(Grid)({
  margin: "1.25rem 0",
});

export const GridTopicCardsContainer = styling("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "3rem",

  "@media (max-width: 1279px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media (max-width: 640px)": {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
});

export const ContainerStyled = styling(Grid)({
  marginBottom: "2.5rem",
});

export const GridChips = withTheme(styled(Grid)`
  && {
    display: flex;
    overflow-y: auto;
    margin: ${(props) =>
      props.$no_margin_top ? "2rem 0 0 0" : "4.5rem 0 3rem 0"};
    padding: 0 0 0.75rem 0;

    /* ScrollBar */
    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 6.25rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${Color.secondaryGrey};
      border-radius: 6.25rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      justify-content: space-between;
      margin: 1.876rem 0;
    }
  }
`);

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    font-weight: bold;
    text-transform: ${(props) => (props.$search_results ? null : "capitalize")};
    font-size: 2rem;
  }
`;

export const NoSearchResults = styling(Typography)({
  fontWeight: "bold",
  fontSize: "1.5625rem",
  marginBottom: "1rem",
});

export const NoSearchResultsSubtitle = styling(Typography)({
  fontSize: "1rem",
  marginBottom: "2.25rem",
});

// Misc. Components //
export const IconComponent = styling(DoneIcon)({
  marginRight: "0.5rem",
});

export const SubCategoryButton = withTheme(styled(Button)`
  && {
    text-transform: capitalize;
    margin-right: 3rem;
    color: ${(props) =>
      props.$selected_subcategory
        ? `${Color.hiredlyPurple}`
        : `${Color.black}`};
    border-bottom: ${(props) =>
      props.$selected_subcategory ? `2px solid` : null};
    border-radius: 0;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      margin: 0;
      font-size: 0.75rem;
    }
  }
`);

export const SkeletonStyled = styling(Skeleton)((props) => ({
  margin: "0.75rem",
  width: "370px",
  height: "235px",
  borderRadius: "1.875rem",

  [props.theme.breakpoints.down(480)]: {
    minWidth: "100%",
    margin: "0 0 0.5rem 0",
  },

  [props.theme.breakpoints.down(770)]: {
    width: "350px",
  },

  [props.theme.breakpoints.up(960)]: {
    width: "390px",
  },
}));

export const ChipStyled = withTheme(styled(Chip)`
  && {
    margin-left: ${(props) => (props.$all ? "0" : "0.5rem")};
    height: 2.5rem;
    border-radius: 1.25rem;
    padding: 0 1rem;

    & .MuiChip-label {
      font-size: 0.75rem;
      font-weight: bold;

      ${(props) => props.theme.breakpoints.down("mobileL")} {
        font-size: 1rem;
        height: auto;
      }
    }
  }
`);

export const SpanStyled = styled.span`
  text-transform: lowercase;
`;

export const CompanyListContainer = styling("div")({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "3rem",

  "@media (max-width: 1279px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media (max-width: 640px)": {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
});

export const PaginationWrapper = styling("div")({
  display: "flex",
  justifyContent: "center",

  width: "100%",
  margin: "1.875rem 0",
});

export const PaginationNumberBox = withTheme(styled(Box)`
  && {
    border-radius: 0.25rem;
    background-color: ${(props) =>
      props.$non_focus === "true" ? null : Color.hiredlyPurple};
    color: ${(props) =>
      props.$non_focus === "true" ? "#00000040" : Color.white};
    margin: ${(props) => (props.$margin === "true" ? "0 1.25rem" : null)};
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`);

export const PageNumber = styling(Typography)({
  //
});

export const IconButtonStyled = withTheme(styled(IconButton)`
  && {
    position: ${(props) =>
      props.$position === "absolute" ? "absolute" : null};
    right: ${(props) => (props.$right === "true" ? "0" : null)};
    top: ${(props) => (props.$top === "true" ? "0.5rem" : null)};
    margin-left: ${(props) =>
      props.$margin_left === "true" ? "0.5rem" : null};
    margin-right: ${(props) =>
      props.$margin_right === "true" ? "0.5rem" : null};
    background-color: ${(props) =>
      props.$pagination === "true" ? Color.grey : null};
    color: ${(props) => (props.$pagination === "true" ? Color.darkGrey : null)};
    width: ${(props) => (props.$pagination === "true" ? "2rem" : null)};
    height: ${(props) => (props.$pagination === "true" ? "2rem" : null)};
    border-radius: ${(props) =>
      props.$pagination === "true" ? "0.25rem" : null};

    &:hover {
      color: ${(props) =>
        props.$pagination === "true" ? Color.hiredlyPurple : null};
      background-color: ${(props) =>
        props.$pagination === "true" ? Color.grey : null};
    }
  }
`);

export const ChevronLeftIconStyled = styling(ChevronLeftIcon)({
  width: "1.25rem",
  height: "1.25rem",
});

export const ChevronRightIconStyled = styling(ChevronRightIcon)({
  width: "1.25rem",
  height: "1.25rem",
});
