import { styled as styling } from "@mui/material/styles";
import Link from "next/link";
import Color from "../../../assets/colors";

export const LinkWrapper = styling(Link)({
  textDecoration: "none",
  color: Color.black,
  width: "100%",
});

export const Wrapper = styling("div")({
  borderRadius: "0.625rem",
  boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.2)",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.15s ease-in-out",
  overflow: "clip",

  ":hover": {
    cursor: "pointer",
    boxShadow: "0 0 0 2px rgba(0,0,0,0.35)",
  },
});

export const AdviceDetailsContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "1rem 1rem",
  flex: 1,
});

export const AdviceTitle = styling("h2")({
  color: Color.textHighEmphasis,

  fontWeight: "700",
  fontSize: "1rem",
  lineHeight: "24px",

  margin: "0",
});

export const AdviceExcerpt = styling("h3")({
  color: Color.textMediumEmphasis,

  fontWeight: "500",
  fontSize: "0.875rem",
  lineHeight: "20px",

  margin: "0",

  "& p": {
    margin: "0",
  },
});

export const AdviceImageBanner = styling("img")({
  width: "100%",
  height: "240px",
  objectFit: "cover",
});
