import Grid from "@mui/material/Grid";
import { useIsClient } from "@uidotdev/usehooks";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  clearBlogs,
  clearPagination,
  getArticleCategories,
  getBlogs,
  requestArticle,
  updateSearchQuery,
  updateSelectedCategory,
  updateSelectedSubcategory,
  updateStaticSearchQuery,
} from "../../../redux/actions/advice_action";
import { getUser } from "../../../redux/actions/user_action";
import Button from "../../shared/SharedButton/SharedButton";
import AdviceCard from "../AdviceCard/AdviceCard";
import {
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  ChipStyled,
  ContainerStyled,
  GridChips,
  GridTitle,
  GridTopicCardsContainer,
  IconButtonStyled,
  IconComponent,
  NoSearchResults,
  NoSearchResultsSubtitle,
  PageNumber,
  PaginationNumberBox,
  PaginationWrapper,
  SkeletonStyled,
  SubCategoryButton,
  TitleStyled,
} from "./styles";

export default function AdviceTrendingTopics(props) {
  const { adviceAd, serverPageParams } = props;

  const dispatch: any = useDispatch();

  const isClient = useIsClient();

  const [renderedBlogs, setRenderedBlogs] = useState<any[]>(adviceAd);

  const [requestArticleStatus, setRequestArticleStatus] = useState<
    "pending" | "loading" | "success"
  >("pending");

  const fetchingBlogs = useSelector(
    (state: any) => state?.advice?.fetchingBlogs
  );
  const blogs = useSelector((state: any) => state?.advice?.blogs);
  const categories = useSelector((state: any) => state?.advice?.categories);
  const selectedCategory = useSelector(
    (state: any) => state?.advice?.selectedCategory
  );
  const selectedSubcategory = useSelector(
    (state: any) => state?.advice?.selectedSubcategory
  );
  const staticSearchQuery = useSelector(
    (state: any) => state?.advice?.staticSearchQuery
  );

  const user = getUser();
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  function handleCategoryClick(category: any) {
    if (
      category.attributes?.categoryId ===
      selectedCategory.attributes?.categoryId
    ) {
      return;
    }
    dispatch(updateSelectedCategory(category));
    // Set default selected subcategory to the first one
    const firstSubcategory =
      category.attributes?.articleSubcategories?.data[0] ?? {};
    dispatch(updateSelectedSubcategory(firstSubcategory));

    // Clean up logic
    dispatch(clearPagination());
    dispatch(updateSearchQuery(""));
    dispatch(updateStaticSearchQuery(""));

    handleLoadAdvice(
      category.attributes?.categoryId,
      firstSubcategory.attributes?.subcategoryId,
      0
    );
  }

  function handleSubcategoryClick(subcategory: any) {
    if (
      subcategory.attributes?.subcategoryId ===
      selectedSubcategory.attributes?.subcategoryId
    ) {
      return;
    }

    dispatch(updateSelectedSubcategory(subcategory));
    dispatch(clearPagination());

    handleLoadAdvice(
      selectedCategory.attributes?.categoryId,
      subcategory.attributes?.subcategoryId,
      0
    );
  }

  function handleLoadAdvice(
    currentCategoryId: string = "",
    currentSubcategoryId: string = "",
    currentPage: number = 0
  ) {
    scrollTo(0, 500);
    dispatch(clearBlogs());

    let params = {
      start: currentPage,
      limit: 15,
      categoryId: currentCategoryId,
      subcategoryId: currentSubcategoryId,
    };
    dispatch(getBlogs(params));
  }

  function handleRequestTopicClick() {
    setRequestArticleStatus("loading");
    if (user) {
      const params = {
        canCancel: false,
        email: user.email,
        articleTitle: staticSearchQuery,
      };
      dispatch(requestArticle(params)).then((res) => {
        if (res.success) {
          triggerSnackbarFunc({
            snackbarMessage: "Your article has been successfully requested!",
            severity: "success",
          });
        } else {
          triggerSnackbarFunc({
            snackbarMessage: "Something went wrong, please try again later.",
            severity: "error",
          });
        }
      });
    } else {
      setRequestArticleStatus("pending");
      triggerSnackbarFunc({
        snackbarMessage: "Please log in to request for articles.",
        severity: "error",
      });
    }
  }

  useEffect(() => {
    if (isClient) {
      setRenderedBlogs(blogs);
    }
  }, [isClient, blogs]);

  useEffect(() => {
    dispatch(clearPagination());
    dispatch(getArticleCategories());

    handleLoadAdvice(serverPageParams);
  }, [dispatch]);

  return (
    <ContainerStyled>
      <GridChips>
        <ChipStyled
          id={"blog-category-filter-button"}
          $all
          label="All"
          onClick={() => handleCategoryClick({ categoryId: null })}
          variant="default"
          style={
            !selectedCategory.attributes?.categoryId
              ? { background: "black", color: "white" }
              : {}
          }
        />
        {categories.map((category: any, index: number) => (
          <ChipStyled
            id={"blog-category-filter-button"}
            label={category.attributes?.category}
            onClick={() => handleCategoryClick(category)}
            variant="outlined"
            key={index}
            value={category.attributes?.categoryId}
            style={
              selectedCategory.attributes?.categoryId ==
              category.attributes?.categoryId
                ? { background: "black", color: "white" }
                : {}
            }
          />
        ))}
      </GridChips>
      <GridTitle>
        {renderedBlogs?.length === 0 && !fetchingBlogs ? (
          // No results found
          <div>
            <NoSearchResults>
              Sorry, we couldn't find any match for "{staticSearchQuery}"
            </NoSearchResults>
            <NoSearchResultsSubtitle>
              Request for this topic and we will work on it! You will be
              notified through email once this topic becomes available.
            </NoSearchResultsSubtitle>
            <Button
              button_type={"SolidPurple"}
              desktop_width={"Medium"}
              text_transform={"capitalize"}
              // TODO: resolve TypeScript type error
              // @ts-ignore
              variant="contained"
              color="primary"
              onClick={handleRequestTopicClick}
              disabled={requestArticleStatus === "loading"}
            >
              {requestArticleStatus === "success" ? (
                <>
                  <IconComponent />
                  <span>Topic Requested</span>
                </>
              ) : (
                "Request Topic"
              )}
            </Button>
          </div>
        ) : staticSearchQuery ? (
          <TitleStyled $search_results>
            Search results for "{staticSearchQuery}"
          </TitleStyled>
        ) : !selectedCategory.attributes?.categoryId ? (
          <TitleStyled>What Do You Want to Work on Today?</TitleStyled>
        ) : (
          <TitleStyled>{selectedCategory.attributes?.category}</TitleStyled>
        )}
        <GridChips $no_margin_top>
          {selectedCategory.attributes?.articleSubcategories?.data?.map(
            (value: any, index: number) => {
              return (
                <SubCategoryButton
                  onClick={() => handleSubcategoryClick(value)}
                  variant="text"
                  $selected_subcategory={
                    selectedSubcategory.attributes?.subcategoryId ==
                    value.attributes?.subcategoryId
                  }
                  key={index}
                >
                  {value.attributes?.subcategory}
                </SubCategoryButton>
              );
            }
          )}
        </GridChips>
      </GridTitle>
      <GridTopicCardsContainer>
        {!fetchingBlogs || renderedBlogs?.length > 0
          ? // Has results
            renderedBlogs?.map((blog: any) => (
              <AdviceCard key={blog.id} blog={blog?.attributes} />
            ))
          : Array.from(new Array(6)).map((array, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <SkeletonStyled variant="rectangular" />
                </Grid>
              );
            })}
        {/* Loading state for next page */}
        {fetchingBlogs &&
          blogs.length > 0 &&
          Array.from(new Array(6)).map((e, index) => (
            <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
              <SkeletonStyled variant="rectangular" />
            </Grid>
          ))}
      </GridTopicCardsContainer>

      <Pagination
        handleLoadAdvice={handleLoadAdvice}
        fetchingBlogs={fetchingBlogs}
      />
    </ContainerStyled>
  );
}

function Pagination(props: any) {
  const { handleLoadAdvice, fetchingBlogs } = props;

  const isClient = useIsClient();

  const selectedCategory = useSelector(
    (state: any) => state?.advice?.selectedCategory
  );
  const selectedSubcategory = useSelector(
    (state: any) => state?.advice?.selectedSubcategory
  );
  const pagination = useSelector((state: any) => state?.advice?.pagination);

  const currentPage = pagination?.page ?? 1;
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < (pagination?.pageCount ?? 1);

  function handlePreviousPage() {
    if (!hasPreviousPage || fetchingBlogs) return;

    const prevPage = currentPage - 1;
    const start = (prevPage - 1) * (pagination?.pageSize ?? 15);
    handleLoadAdvice(
      selectedCategory.attributes?.categoryId,
      selectedSubcategory.attributes?.subcategoryId,
      start
    );
  }

  function handleNextPage() {
    if (!hasNextPage || fetchingBlogs) return;

    const start = currentPage * (pagination?.pageSize ?? 15);
    handleLoadAdvice(
      selectedCategory.attributes?.categoryId,
      selectedSubcategory.attributes?.subcategoryId,
      start
    );
  }

  return hasPreviousPage || hasNextPage ? (
    <PaginationWrapper>
      {hasPreviousPage ? (
        <Fragment>
          <IconButtonStyled
            $margin_right="true"
            $pagination="true"
            disableRipple
            onClick={() => handlePreviousPage()}
            disabled={!hasPreviousPage || fetchingBlogs}
          >
            {isClient && <ChevronLeftIconStyled />}
          </IconButtonStyled>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage - 1}</PageNumber>
          </PaginationNumberBox>
        </Fragment>
      ) : null}

      <PaginationNumberBox $margin="true">
        <PageNumber variant="subtitle2">{currentPage}</PageNumber>
      </PaginationNumberBox>

      {hasNextPage && (
        <Fragment>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage + 1}</PageNumber>
          </PaginationNumberBox>
          <IconButtonStyled
            $margin_left="true"
            $pagination="true"
            disableRipple
            onClick={() => handleNextPage()}
            disabled={!hasNextPage || fetchingBlogs}
          >
            {isClient && <ChevronRightIconStyled />}
          </IconButtonStyled>
        </Fragment>
      )}
    </PaginationWrapper>
  ) : null;
}
